import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList, Image, TouchableOpacity, ActivityIndicator, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const ConsignmentDetails = ({ route, navigation }) => {
  const { consignment } = route.params;
  const [products, setProducts] = useState([]);
  const [customProducts, setCustomProducts] = useState([]);
  const [shippingFee, setShippingFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [deletingProductId, setDeletingProductId] = useState(null);
  const [deletingCustomProductIndex, setDeletingCustomProductIndex] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);

      try {
        const fetchedProducts = await Promise.all(
          consignment.acf.products.map(async (productId) => {
            const response = await fetch(
              `https://juwelenorogem.be/en/wp-json/wc/v3/products/${productId}?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`
            );
            if (!response.ok) {
              console.error(`Failed to fetch product ${productId}`);
              return null;
            }
            const productData = await response.json();
            if (productData.images?.[0]?.src) {
              await Image.prefetch(productData.images[0].src);
            }

            const resellerPriceAttr = productData.attributes.find(
              (attr) => attr.slug === 'pa_numberusd'
            );
            const resellerPrice = resellerPriceAttr
              ? parseFloat(resellerPriceAttr.options[0]) || 0
              : 0;

            return { ...productData, resellerPrice };
          })
        );

        const validProducts = fetchedProducts.filter((product) => product !== null);
        setProducts(validProducts);

        const fetchedCustomProducts = consignment.acf.custom_products || [];
        setCustomProducts(fetchedCustomProducts);

        const fetchedShippingFee = parseFloat(consignment.acf.shipping_fee || 0);
        setShippingFee(fetchedShippingFee);

        const productTotal = validProducts.reduce((sum, product) => sum + product.resellerPrice, 0);
        const customProductTotal = fetchedCustomProducts.reduce(
          (sum, customProduct) => sum + parseFloat(customProduct.price || 0),
          0
        );

        setTotal(productTotal + customProductTotal + fetchedShippingFee);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }

      setIsLoading(false);
    };

    fetchProducts();
  }, [consignment.acf.products, consignment.acf.custom_products, consignment.acf.shipping_fee]);

  const formatDate = (dateString) => {
    if (!dateString || dateString.length !== 8) return dateString;
    return `${dateString.slice(6, 8)}/${dateString.slice(4, 6)}/${dateString.slice(0, 4)}`;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(price);
  };

  const deleteProduct = async (productId) => {
    console.log('Delete button clicked for product ID:', productId);
  
    const productToRemove = products.find((product) => product.id === productId);
    console.log('Product to remove:', productToRemove);
  
    if (!productToRemove) {
      console.error(`Product with ID ${productId} not found in products array`);
      return;
    }
  
    // Confirmation dialog
    const confirmation = window.confirm(
      products.length === 1
        ? 'Deleting this product will remove the entire consignment. Continue?'
        : 'Are you sure you want to delete this product?'
    );
  
    if (!confirmation) {
      console.log('Delete canceled');
      return;
    }
  
    console.log('Delete confirmed for product ID:', productId);
    setDeletingProductId(productId); // Set loading state for the button
  
    try {
      console.log('Starting reassignment of product...');
      const response = await fetch(
        `https://juwelenorogem.be/wp-json/wc/v3/products/${productId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await AsyncStorage.getItem('jwtToken')}`,
          },
          body: JSON.stringify({
            meta_data: [{ key: 'location', value: 697 }],
          }),
        }
      );
  
      console.log('Reassign response status:', response.status);
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Reassigning product failed. Response:', errorText);
        throw new Error(`Failed to reassign product ${productId}`);
      }
  
      if (products.length === 1) {
        console.log('Only one product left, deleting the consignment...');
        const deleteResponse = await fetch(
          `https://juwelenorogem.be/wp-json/wp/v2/consignments/${consignment.id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${await AsyncStorage.getItem('jwtToken')}`,
            },
          }
        );
  
        console.log('Consignment delete response status:', deleteResponse.status);
        if (!deleteResponse.ok) {
          const deleteErrorText = await deleteResponse.text();
          console.error('Deleting consignment failed. Response:', deleteErrorText);
          throw new Error(`Failed to delete consignment ${consignment.id}`);
        }
  
        alert('The consignment has been deleted.');
        navigation.goBack();
        return;
      }
  
      console.log('Updating consignment to remove the product...');
      const updatedProductIds = consignment.acf.products.filter((id) => id !== productId);
  
      const updateConsignmentResponse = await fetch(
        `https://juwelenorogem.be/wp-json/wp/v2/consignments/${consignment.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await AsyncStorage.getItem('jwtToken')}`,
          },
          body: JSON.stringify({
            acf: { ...consignment.acf, products: updatedProductIds },
          }),
        }
      );
  
      console.log(
        'Consignment update response status:',
        updateConsignmentResponse.status
      );
      if (!updateConsignmentResponse.ok) {
        const updateErrorText = await updateConsignmentResponse.text();
        console.error('Updating consignment failed. Response:', updateErrorText);
        throw new Error(`Failed to update consignment ${consignment.id}`);
      }
  
      console.log('Updating local state...');
      const updatedProducts = products.filter((product) => product.id !== productId);
      setProducts(updatedProducts);
      setTotal((prev) => prev - productToRemove.resellerPrice);
      console.log('Product successfully removed. Updated total:', total);
    } catch (error) {
      console.error('Error during product deletion process:', error.message);
      alert('Failed to delete product.');
    } finally {
      console.log('Resetting deleting state for product ID:', productId);
      setDeletingProductId(null);
    }
  };
  
  const deleteCustomProduct = async (index) => {
    console.log('Delete button clicked for custom product at index:', index);
  
    setDeletingCustomProductIndex(index); // Set the deleting state
  
    // Remove the product locally
    const updatedCustomProducts = customProducts.filter((_, i) => i !== index);
    setCustomProducts(updatedCustomProducts);
    console.log('Removed custom product:', updatedCustomProducts);
  
    try {
      const token = await AsyncStorage.getItem('jwtToken');
  
      if (!token) {
        throw new Error('Token not found. Please log in again.');
      }
  
      // Prepare payload
      const payload = {
        acf: {
          ...consignment.acf,
          custom_products: updatedCustomProducts,
        },
      };
  
      console.log('Payload sent to API:', payload);
  
      // Update consignment on WordPress
      const response = await fetch(
        `https://juwelenorogem.be/wp-json/wp/v2/consignments/${consignment.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Updating consignment failed. Response:', errorText);
        throw new Error(`Failed to update consignment with ID: ${consignment.id}`);
      }
  
      console.log('Consignment updated successfully.');
    } catch (error) {
      console.error('Error updating consignment after custom product deletion:', error.message);
      Alert.alert('Error', error.message || 'Failed to update consignment. Please try again.');
    } finally {
      setDeletingCustomProductIndex(null); // Reset the deleting state
    }
  };
  
  
  
  



  const renderProduct = ({ item }) => (
    <View style={styles.productItem}>
      <Image
        source={{ uri: item.images?.[0]?.src || 'https://via.placeholder.com/100' }}
        style={styles.productImage}
      />
      <View style={styles.productDetails}>
        <Text style={styles.productName}>{item.name}</Text>
        <Text style={styles.productSku}>SKU: {item.sku}</Text>
        <Text style={styles.productPrice}>Price: {formatPrice(item.resellerPrice)}</Text>
      </View>
      <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => deleteProduct(item.id)}
        disabled={deletingProductId === item.id}
      >
        <Text style={styles.deleteButtonText}>
          {deletingProductId === item.id ? `Deleting...` : 'Delete'}
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderCustomProduct = ({ item, index }) => (
    <View style={styles.productItem}>
      <Image
        source={{ uri: 'https://dummyimage.com/100x100' }}
        style={styles.productImage}
      />
      <View style={styles.productDetails}>
        <Text style={styles.productName}>{item.name}</Text>
        <Text style={styles.productSku}>SKU: {item.sku || 'N/A'}</Text>
        <Text style={styles.productPrice}>Price: {formatPrice(item.price)}</Text>
      </View>
      <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => deleteCustomProduct(index)}
        disabled={deletingCustomProductIndex === index}
      >
        <Text style={styles.deleteButtonText}>
          {deletingCustomProductIndex === index ? 'Deleting...' : 'Delete'}
        </Text>
      </TouchableOpacity>
    </View>
  );
  
  

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#0000ff" />
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.detailsContainer}>
        <Text style={styles.title}>Consignment Details</Text>
        <Text style={styles.detailText}>Reseller: {consignment.resellerName}</Text>
        <Text style={styles.detailText}>Date: {formatDate(consignment.acf.date)}</Text>
        <FlatList
          data={products}
          keyExtractor={(item) => item.id.toString()}
          renderItem={renderProduct}
          ListHeaderComponent={
            customProducts.length > 0 && (
              <FlatList
                data={customProducts}
                keyExtractor={(item, index) => `custom-${index}`}
                renderItem={renderCustomProduct}
                style={{ marginBottom: 20 }}
              />
            )
          }
          ListFooterComponent={
            <View style={styles.totalContainer}>
              <Text style={styles.totalText}>
                Total: {formatPrice(total)}
              </Text>
              {shippingFee > 0 && (
                <Text style={styles.shippingText}>
                  Shipping Fee: {formatPrice(shippingFee)}
                </Text>
              )}
            </View>
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20, backgroundColor: '#f9f9f9' },
  detailsContainer: {
    width: 1080,
    alignSelf: "center",
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 15,
    marginVertical: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    flex: 1,
  },
  title: { fontSize: 24, fontWeight: 'bold', marginBottom: 20 },
  detailText: { fontSize: 16, marginBottom: 10 },
  productItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 10,
  },
  productImage: { width: 100, height: 100, marginRight: 10 },
  productDetails: { flex: 1 },
  productName: { fontSize: 16, fontWeight: 'bold' },
  productSku: { fontSize: 14, color: '#555' },
  productPrice: { fontSize: 14, color: '#555' },
  totalContainer: { marginTop: 20 },
  totalText: { fontSize: 18, fontWeight: 'bold', color: '#000' },
  shippingText: { fontSize: 14, color: '#333', marginTop: 5 },
  deleteButton: {
    backgroundColor: '#ff4d4d',
    padding: 8,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
  deleteButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 12,
  },
});

export default ConsignmentDetails;
