import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Picker, Alert, Platform } from 'react-native';
import { printAsync } from 'expo-print';

const CertificateScreen = ({ route }) => {
  const { selectedItem } = route.params || {};

  const [title, setTitle] = useState('Solitaire Ring'); // Default title
  const [goldWeight, setGoldWeight] = useState(''); // Manual gold weight input
  const [metal, setMetal] = useState('18k White Gold'); // Default metal
  const [language, setLanguage] = useState('en'); // 'en' for English, 'nl' for Dutch
  const [productImage, setProductImage] = useState(
    selectedItem?.images?.[0]?.src || 'https://via.placeholder.com/300' // Default to placeholder if no image
  );
  const [price, setPrice] = useState(''); // State to store the price

  const titleOptions = {
    en: [
      'Solitaire Ring',
      'Ring',
      'Three-stone Ring',
      'Halo Ring',
      'Necklace',
      'Pendant',
      'Earrings',
      'Piercing',
      'Bracelet',
      'Tennis Bracelet',
    ],
    nl: [
      'Solitaire Ring',
      'Ring',
      'Drie-steen Ring',
      'Halo Ring',
      'Halsketting',
      'Hanger',
      'Oorbellen',
      'Piercing',
      'Armband',
      'Tennis Armband',
    ],
  };

  // Editable attributes
  const [shapeCentralStone, setShapeCentralStone] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_shape-central-stone')?.options[0] || ''
  );
  const [weightCentralStone, setWeightCentralStone] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_weight-central-stone')?.options[0] || ''
  );
  const [colorCentralStone, setColorCentralStone] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_color-central-stone')?.options[0] || ''
  );
  const [clarityCentralStone, setClarityCentralStone] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_clarity-central-stone')?.options[0] || ''
  );
  const [certificate, setCertificate] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_certificate')?.options[0] || ''
  );
  const [certificateNumber, setCertificateNumber] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_certificate-number')?.options[0] || ''
  );

  const [shapeSideStones, setShapeSideStones] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_shape-side-stones')?.options[0] || ''
  );
  const [weightSideStones, setWeightSideStones] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_weight-side-stones')?.options[0] || ''
  );
  const [colorSideStones, setColorSideStones] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_color-side-stones')?.options[0] || ''
  );
  const [claritySideStones, setClaritySideStones] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_clarity-side-stones')?.options[0] || ''
  );
  const [gemstoneCentralStone, setGemstoneCentralStone] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_gemstone-central-stone')?.options[0] || ''
  );
  const [gemstoneSideStones, setGemstoneSideStones] = useState(
    selectedItem?.attributes?.find((attr) => attr.slug === 'pa_gemstone-side-stones')?.options[0] || ''
  );

  const generateCertificateContent = () => {
    const localizedLabels = {
      title: language === 'en' ? 'Title' : 'Titel',
      goldWeight: language === 'en' ? 'Gold Weight:' : 'Goud Gewicht:',
      metal: language === 'en' ? 'Metal:' : 'Metaal:',
      shapeCentralStone: language === 'en' ? 'Shape Central Stone:' : 'Vorm Centrale Steen:',
      weightCentralStone: language === 'en' ? 'Weight Central Stone:' : 'Gewicht Centrale Steen:',
      colorCentralStone: language === 'en' ? 'Color Central Stone:' : 'Kleur Centrale Steen:',
      clarityCentralStone: language === 'en' ? 'Clarity Central Stone:' : 'Zuiverheid Centrale Steen:',
      certificate: language === 'en' ? 'Certificate:' : 'Certificaat:',
      shapeSideStones: language === 'en' ? 'Shape Side Stones:' : 'Vorm Zijstenen:',
      sideStoneWeight: language === 'en' ? 'Weight Side Stones:' : 'Gewicht Zijstenen:',
      sideStoneColor: language === 'en' ? 'Color Side Stones:' : 'Kleur Zijstenen:',
      sideStoneClarity: language === 'en' ? 'Clarity Side Stones:' : 'Zuiverheid Zijstenen:',
      date: language === 'en' ? 'Date:' : 'Datum:',
      gemstoneCentralStone: language === 'en' ? 'Gemstone Central Stone:' : 'Edelsteen Centrale Steen:',
      gemstoneSideStones: language === 'en' ? 'Gemstone Side Stones:' : 'Edelsteen Zijstenen:',
    };
  
    const fieldConfig = [
      { key: 'title', label: localizedLabels.title, value: title, printLabel: false },
      { key: 'goldWeight', label: localizedLabels.goldWeight, value: goldWeight ? `${goldWeight}g` : null },
      { key: 'price', label: 'Price', value: price ? `${price} €` : null }, // New price field

      { key: 'metal', label: localizedLabels.metal, value: metal },
      { key: 'gemstoneCentralStone', label: localizedLabels.gemstoneCentralStone, value: gemstoneCentralStone },
      { key: 'shapeCentralStone', label: localizedLabels.shapeCentralStone, value: shapeCentralStone },
      { key: 'weightCentralStone', label: localizedLabels.weightCentralStone, value: weightCentralStone },
      { key: 'colorCentralStone', label: localizedLabels.colorCentralStone, value: colorCentralStone },
      { key: 'clarityCentralStone', label: localizedLabels.clarityCentralStone, value: clarityCentralStone },
      { key: 'certificate', label: localizedLabels.certificate, value: certificate ? `${certificate} ${certificateNumber}` : null },
      { key: 'gemstoneSideStones', label: localizedLabels.gemstoneSideStones, value: gemstoneSideStones },
      { key: 'shapeSideStones', label: localizedLabels.shapeSideStones, value: shapeSideStones },
      { key: 'sideStoneWeight', label: localizedLabels.sideStoneWeight, value: weightSideStones },
      { key: 'sideStoneColor', label: localizedLabels.sideStoneColor, value: colorSideStones },
      { key: 'sideStoneClarity', label: localizedLabels.sideStoneClarity, value: claritySideStones },
      { key: 'date', label: localizedLabels.date, value: new Date().toLocaleString(language === 'en' ? 'en-US' : 'nl-NL', {
          month: 'long',
          year: 'numeric',
        }) },
    ];
  
    const renderField = (field) => {
      if (!field.value) return '';
      if (field.key === 'title') {
        // Render the title with larger font size
        return `<h2 style="font-size: 2em; margin-bottom: 20px; text-align: center;">${field.value}</h2>`;
      }
      return field.printLabel === false
        ? `<p>${field.value}</p>` // Only print the value
        : `<p><strong>${field.label}</strong> ${field.value}</p>`; // Print label and value
    };
    
  
    return `
      <html>
        <body style="font-family: Arial, sans-serif;">
          <div style="display: flex; flex-direction: row; height: 100%; width: 100%;">
            <!-- Left Column: Image -->
            <div style="width: 9.9cm; text-align: center; position: relative;">
              <div style="position: absolute; top: 5cm; width: 100%; text-align: center;">
                ${
                  productImage
                    ? `<img src="${productImage}" alt="Product Image" style="display: block; margin: 0 auto; max-width: 6cm; max-height: 6cm; object-fit: contain; border: 1px solid #ccc;" />`
                    : '<p>No Image Available</p>'
                }
              </div>
            </div>
  
            <!-- Center Column: Jewel Data -->
            <div style="width: 9.9cm; padding: 20px;">
              ${fieldConfig.map(renderField).join('')}
            </div>
            <div style="width: 9.9cm; padding: 20px;"></div>
          </div>
        </body>
      </html>
    `;
  };
  

const printCertificate = async () => {
  try {
    const content = generateCertificateContent();

    if (Platform.OS === 'web') {
      const image = new Image();
      image.src = productImage;

      image.onload = () => {
        const win = window.open();
        win.document.write(content);
        win.print();
        win.close();
      };

      image.onerror = () => {
        Alert.alert('Error', 'Failed to load the product image. Please check the image URL.');
      };
    } else {
      // On native, just use the existing printAsync method
      const { uri } = await printAsync({ html: content });
      await printAsync({ uri });
    }
  } catch (error) {
    Alert.alert('Error', 'Failed to print the certificate. Please try again.');
  }
};


  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Text style={styles.title}>Certificate Information</Text>

        <View style={styles.section}>
          <Text style={styles.label}>Language</Text>
          <Picker selectedValue={language} onValueChange={setLanguage}>
            <Picker.Item label="English" value="en" />
            <Picker.Item label="Dutch" value="nl" />
          </Picker>
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>{language === 'en' ? 'Title' : 'Titel'}</Text>
          <Picker selectedValue={title} onValueChange={setTitle}>
            {titleOptions[language].map((option, index) => (
              <Picker.Item key={index} label={option} value={option} />
            ))}
          </Picker>
        </View>

        {/* Gold Weight and Metal */}
        <View style={styles.section}>
          <Text style={styles.label}>Gold Weight</Text>
          <TextInput
            style={styles.input}
            value={goldWeight}
            onChangeText={setGoldWeight}
            keyboardType="numeric"
          />
        </View>
        <View style={styles.section}>
  <Text style={styles.label}>Price</Text>
  <TextInput
    style={styles.input}
    value={price}
    onChangeText={setPrice}
    keyboardType="numeric" // To ensure numeric input
    placeholder="Enter price in €"
  />
</View>
        <View style={styles.section}>
          <Text style={styles.label}>Metal</Text>
          <Picker selectedValue={metal} onValueChange={setMetal}>
            <Picker.Item label="18k White Gold" value="18k White Gold" />
            <Picker.Item label="18k Yellow Gold" value="18k Yellow Gold" />
            <Picker.Item label="18k Rose Gold" value="18k Rose Gold" />
            <Picker.Item label="Platinum" value="Platinum" />
            <Picker.Item label="Palladium" value="Palladium" />
          </Picker>
        </View>

        {/* Central and Side Stones */}
        <View style={styles.section}>
  <Text style={styles.label}>Gemstone Central Stone</Text>
  <TextInput
    style={styles.input}
    value={gemstoneCentralStone}
    onChangeText={setGemstoneCentralStone}
  />
</View>
        <View style={styles.section}>
          <Text style={styles.label}>Shape Central Stone</Text>
          <TextInput
            style={styles.input}
            value={shapeCentralStone}
            onChangeText={setShapeCentralStone}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Weight Central Stone</Text>
          <TextInput
            style={styles.input}
            value={weightCentralStone}
            onChangeText={setWeightCentralStone}
            keyboardType="numeric"
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Color Central Stone</Text>
          <TextInput
            style={styles.input}
            value={colorCentralStone}
            onChangeText={setColorCentralStone}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Clarity Central Stone</Text>
          <TextInput
            style={styles.input}
            value={clarityCentralStone}
            onChangeText={setClarityCentralStone}
          />
        </View>
        <View style={styles.section}>
  <Text style={styles.label}>Gemstone Side Stones</Text>
  <TextInput
    style={styles.input}
    value={gemstoneSideStones}
    onChangeText={setGemstoneSideStones}
  />
</View>
        <View style={styles.section}>
          <Text style={styles.label}>Shape Side Stones</Text>
          <TextInput
            style={styles.input}
            value={shapeSideStones}
            onChangeText={setShapeSideStones}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Weight Side Stones</Text>
          <TextInput
            style={styles.input}
            value={weightSideStones}
            onChangeText={setWeightSideStones}
            keyboardType="numeric"
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Color Side Stones</Text>
          <TextInput
            style={styles.input}
            value={colorSideStones}
            onChangeText={setColorSideStones}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Clarity Side Stones</Text>
          <TextInput
            style={styles.input}
            value={claritySideStones}
            onChangeText={setClaritySideStones}
          />
        </View>

        <TouchableOpacity style={styles.button} onPress={printCertificate}>
          <Text style={styles.buttonText}>Print Certificate</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  scrollContainer: {
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  section: {
    marginBottom: 20,
    width: '100%',
  },
  label: {
    fontSize: 18,
    marginBottom: 10,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    borderRadius: 5,
    fontSize: 16,
    backgroundColor: '#f9f9f9',
  },
  button: {
    backgroundColor: '#007BFF',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
  },
});

export default CertificateScreen;
