import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList, ActivityIndicator, ScrollView, Picker, TouchableOpacity } from 'react-native';

const OrdersList = ({ navigation }) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMoreOrders, setHasMoreOrders] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedFrom, setSelectedFrom] = useState('all');

  const orderStatuses = [
    { label: 'All', value: 'all' },
    { label: 'Pending Payment', value: 'pending' },
    { label: 'Processing', value: 'processing' },
    { label: 'On Hold', value: 'on-hold' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Refunded', value: 'refunded' },
    { label: 'Failed', value: 'failed' },
  ];

  const fromOptions = [
    { label: 'All', value: 'all' },
    { label: 'Shop', value: 'Shop' },
    { label: 'Online', value: 'Online' },
  ];

  const fetchOrders = async () => {
    try {
      const response = await fetch(
        `https://juwelenorogem.be/en/wp-json/wc/v3/orders?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}&page=${page}&per_page=20`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }
      const data = await response.json();

      if (data.length < 20) {
        setHasMoreOrders(false);
      }

      const formattedData = data.map((order) => {
        const fromValue = extractMetaData(order.meta_data, 'from');
        return {
          ...order,
          from: fromValue === 'N/A' ? 'Online' : fromValue,
        };
      });

      setOrders((prevOrders) => [...prevOrders, ...formattedData]);
    } catch (error) {
      console.error('Error fetching orders:', error.message || error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [page]);

  useEffect(() => {
    filterOrders();
  }, [orders, selectedStatus, selectedFrom]);

  const loadMoreOrders = () => {
    if (hasMoreOrders) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const extractMetaData = (metaData, key) => {
    const meta = metaData.find((item) => item.key === key);
    return meta ? meta.value : 'N/A';
  };

  const filterOrders = () => {
    let filtered = [...orders];

    if (selectedStatus !== 'all') {
      filtered = filtered.filter((order) => order.status === selectedStatus);
    }

    if (selectedFrom !== 'all') {
      filtered = filtered.filter((order) => order.from === selectedFrom);
    }

    setFilteredOrders(filtered);
  };

  const renderHeader = () => (
    <View style={styles.tableHeader}>
      <Text style={[styles.cell, styles.headerCell]}>Order ID</Text>
      <Text style={[styles.cell, styles.headerCell]}>Customer Name</Text>
      <Text style={[styles.cell, styles.headerCell]}>Payment Method</Text>
      <Text style={[styles.cell, styles.headerCell]}>From</Text>
      <Text style={[styles.cell, styles.headerCell]}>Source</Text>
      <Text style={[styles.cell, styles.headerCell]}>VAT</Text>
      <Text style={[styles.cell, styles.headerCell]}>Country VAT</Text>
      <Text style={[styles.cell, styles.headerCell]}>Amount</Text>
    </View>
  );

  const renderOrder = ({ item }) => {
    const vat = parseFloat(item.total_tax).toFixed(2);
    const total = parseFloat(item.total).toFixed(2);
    const country = item.billing.country || 'N/A';
  
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate('OrderDetails', { orderId: item.id })}
        style={styles.tableRow}
      >
        <Text style={styles.cell}>{item.id}</Text>
        <Text style={styles.cell}>
          {item.billing.first_name} {item.billing.last_name}
        </Text>
        <Text style={styles.cell}>{item.payment_method_title}</Text>
        <Text style={styles.cell}>{item.from}</Text>
        <Text style={styles.cell}>{extractMetaData(item.meta_data, 'source')}</Text>
        <Text style={styles.cell}>{vat} €</Text>
        <Text style={styles.cell}>{country}</Text>
        <Text style={styles.cell}>{total} €</Text>
      </TouchableOpacity>
    );
  };
  

  if (isLoading && page === 1) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
        <Text>Loading orders...</Text>
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.title}>Orders List</Text>
      <View style={styles.filters}>
        <View style={styles.filter}>
          <Text>Status:</Text>
          <Picker
            selectedValue={selectedStatus}
            onValueChange={(value) => setSelectedStatus(value)}
            style={styles.picker}
          >
            {orderStatuses.map((status) => (
              <Picker.Item key={status.value} label={status.label} value={status.value} />
            ))}
          </Picker>
        </View>
        <View style={styles.filter}>
          <Text>From:</Text>
          <Picker
            selectedValue={selectedFrom}
            onValueChange={(value) => setSelectedFrom(value)}
            style={styles.picker}
          >
            {fromOptions.map((option) => (
              <Picker.Item key={option.value} label={option.label} value={option.value} />
            ))}
          </Picker>
        </View>
      </View>
      <FlatList
  data={filteredOrders}
  keyExtractor={(item) => item.id.toString()}
  renderItem={renderOrder}
  onEndReached={loadMoreOrders}
  onEndReachedThreshold={0.5}
  ListHeaderComponent={renderHeader}
  ListFooterComponent={
    hasMoreOrders ? (
      <ActivityIndicator size="small" color="#0000ff" />
    ) : (
      <Text style={styles.endText}>No more orders to load</Text>
    )
  }
/>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#000',
    paddingVertical: 10,
    backgroundColor: '#f1f1f1',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
  },
  cell: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    paddingHorizontal: 5,
  },
  headerCell: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  filters: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  filter: {
    flex: 1,
    marginHorizontal: 5,
  },
  picker: {
    height: 50,
    backgroundColor: '#f9f9f9',
    borderRadius: 5,
  },
  endText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#555',
    marginVertical: 10,
  },
});

export default OrdersList;
