// HomeStack.js
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './HomeScreen';
import Inventory from './Inventory';
import Selection  from './Selection';
import NewCustomer from './NewCustomer';
import NewReseller from './NewReseller';
import NewOrder from './NewOrder';
import ExistingCustomer from './ExistingCustomer';
import OrderInventory from './OrderInventory';
import ConfirmOrder from './ConfirmOrder';
import CertificateScreen from './CertificateScreen';
import InventoryTemp from './InventoryTemp';
import Consignments from './Consignments';
import ConsignmentDetails from './ConsignmentDetails';
import Orders from './Orders';
import OrderDetails from './OrderDetails';


const Stack = createNativeStackNavigator();

const HomeStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Selection" component={Selection} />
      <Stack.Screen name="Inventory" component={Inventory} />
      <Stack.Screen name="Inventory Temp" component={InventoryTemp} />
      <Stack.Screen name="New Customer" component={NewCustomer} />
      <Stack.Screen name="New Reseller" component={NewReseller} />
      <Stack.Screen name="New Order" component={NewOrder} />
      <Stack.Screen name="Existing Customer" component={ExistingCustomer} />
      <Stack.Screen name="Order Inventory" component={OrderInventory} />
      <Stack.Screen name="Confirm Order" component={ConfirmOrder} />
      <Stack.Screen name="Print Certificate" component={CertificateScreen} />
      <Stack.Screen name="Consignments" component={Consignments} />
      <Stack.Screen name="Consignment Details" component={ConsignmentDetails} />
      <Stack.Screen name="Orders" component={Orders} />
      <Stack.Screen name="OrderDetails" component={OrderDetails} />

    </Stack.Navigator>
  );
};

export default HomeStack;
