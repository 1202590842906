import React, { useState,useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, Alert, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';

const ExistingCustomer = ({ }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation(); // Initialize navigation
  const route = useRoute();

  useEffect(() => {
    if (route.params?.screenTitle) {
      navigation.setOptions({ title: route.params.screenTitle });
    }
  }, [route.params?.screenTitle]);


  const handleSearch = async () => {



    const url = `https://juwelenorogem.be/en/wp-json/wc/v3/customers?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}&search=${searchQuery}&_=${new Date().getTime()}`;
    setIsLoading(true);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      Alert.alert('Error', 'An error occurred while searching for customers.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectCustomer = (customer) => {
    if (route.params?.sourceScreen === 'NewOrder') {
      // Redirect to OrderInventory if we came from NewOrder
      navigation.navigate('Order Inventory', { customer });
    } else if (route.params?.screenTitle === 'New Consignment') {
      navigation.navigate('Consignment', { customer });
    } else {
      navigation.navigate('Invoice Inventory', { customer });
    }
  };
  

  const renderCustomer = ({ item }) => (
    <TouchableOpacity onPress={() => handleSelectCustomer(item)}>
      <View style={styles.customerContainer}>
        <Text style={styles.customerText}>
          {item.first_name} {item.last_name} - 
        </Text>
        <Text style={styles.customerText}>
          {item.email}  
        </Text>
        {item.billing.address_1 && item.billing.city && item.billing.country ? (
          <Text style={styles.customerText}>
             - {item.billing.address_1}, {item.billing.city}, {item.billing.country}
          </Text>
        ) : null}
      </View>
    </TouchableOpacity>
  );
  

  return (
    <View style={styles.container}>
      <View style={styles.centeredContent}>
        <TextInput
          style={styles.input}
          placeholder="Search Customers"
          placeholderTextColor="#888"
          value={searchQuery}
          onChangeText={setSearchQuery}
          onSubmitEditing={handleSearch} // This handles the Enter key press event

        />
        <TouchableOpacity style={styles.button} onPress={handleSearch}>
          <Text style={styles.text}>Search</Text>
        </TouchableOpacity>
        {isLoading ? (
          <Text style={styles.loadingText}>Loading...</Text>
        ) : (
          <FlatList
            data={customers}
            renderItem={renderCustomer}
            keyExtractor={(item) => item.id.toString()}
            contentContainerStyle={styles.listContainer}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  centeredContent: {
    width: '100%',
    alignItems: 'center',
  },
  input: {
    borderWidth: 1,
    borderColor: 'black',
    color: 'black',
    padding: 10,
    marginVertical: 10,
    width: '80%',
    maxWidth:800
  },
  button: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 20,
    alignItems: 'center',
    marginTop: 20,
    minWidth: 270,
  },
  text: {
    color: 'black',
    fontSize: 23,
  },
  loadingText: {
    color: 'black',
    fontSize: 18,
    marginTop: 20,
  },
  listContainer: {
    width: '100%',
    alignItems: 'center',
  },
  customerContainer: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 10,
    marginVertical: 10,
    width: '100%',
    flexDirection: "row",
    justifyContent: "space-between"
  },
  customerText: {
    color: 'black',
    fontSize: 18
  },
});

export default ExistingCustomer;
