import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, SafeAreaView, Platform, Image, Modal,Alert } from 'react-native';
import { printAsync } from 'expo-print';
import Base64ImageComponent from '../assets/Base64ImageComponent';
import DateTimePicker from '@react-native-community/datetimepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchWithReauth, API_BASE_URL } from '../helpers/apiHelper';
import AsyncStorage from '@react-native-async-storage/async-storage';

const ResellerInvoice = ({ navigation, route }) => {
  const { selectedItems = [], customerData } = route.params;
  const [goldCarat, setGoldCarat] = useState("750");
  const [goldWeights, setGoldWeights] = useState({});
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [itemsWithNewPrices, setItemsWithNewPrices] = useState({});
  const [originalPrices, setOriginalPrices] = useState({});
  const [productImages, setProductImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [tempPrices, setTempPrices] = useState({});
  const [multiplier, setMultiplier] = useState(1);
  const userId = customerData.id; // Assuming customerData has an id property
  const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, ''); // Format as DDMMYYYY
  const defaultConsignmentNo = `${currentDate}-${userId}`;
  const [invoiceNo, setInvoiceNo] = useState(defaultConsignmentNo);
  const [localPrices, setLocalPrices] = useState({});
  const [customProducts, setCustomProducts] = useState([]);
  const [isAssigning, setIsAssigning] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [shippingFee, setShippingFee] = useState('');
  const [includeShippingFee, setIncludeShippingFee] = useState(false);
  
  const formatEuro = (value) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
  };
  
  useEffect(() => {
    const calculateInitialValues = async () => {
      const prices = {};
      const origPrices = {};
      const images = {};
  
      for (const item of selectedItems) {
        let price = 0;
  
        // Fetch numberusd from attributes
        const priceAttr = item.attributes.find(attr => attr.slug === 'pa_numberusd');
        if (priceAttr) {
          price = parseFloat(priceAttr.options[0] || '0');
        }
  
        // Log the results for debugging
        console.log(`Product ID: ${item.id}, SKU: ${item.sku}, Resolved NumberUSD: ${price}`);
  
        // Handle invalid or missing prices
        if (isNaN(price)) {
          console.warn(`Invalid price for product ID: ${item.id}. Defaulting to 0.`);
          price = 0;
        }
  
        // Save resolved prices
        prices[item.id] = price.toFixed(2);
        origPrices[item.id] = price.toFixed(2);
  
        // Fetch and convert image to Base64 if available
        const imageUrl = item.images?.[0]?.src || '';
        if (imageUrl) {
          const imageBase64 = await getImageBase64(imageUrl);
          images[item.id] = imageBase64;
        }
      }
  
      // Update state
      setItemsWithNewPrices(prices);
      setOriginalPrices(origPrices);
      setProductImages(images);
      setLoading(false);
    };
  
    calculateInitialValues();
  }, [selectedItems]);
  

  useEffect(() => {
    setItemsWithNewPrices(prevPrices => {
      const updatedPrices = {};
      const updatedTempPrices = {};
      for (const productId in prevPrices) {
        const originalPrice = parseFloat(originalPrices[productId]) || 0;
        const newPrice = (originalPrice * multiplier).toFixed(2);
        updatedPrices[productId] = newPrice;
        updatedTempPrices[productId] = newPrice;
      }
      setTempPrices(updatedTempPrices);
      return updatedPrices;
    });
  }, [multiplier, originalPrices]);
  

  const addCustomProduct = () => {
    setCustomProducts([...customProducts, { id: Date.now(), name: '', sku: '', price: '', details: '' }]);
  };
  
  const handleCustomProductChange = (index, field, value) => {
    const updatedProducts = [...customProducts];
    updatedProducts[index][field] = value;
    setCustomProducts(updatedProducts);
  };

  const handleBulkAssign = async () => {
    setIsAssigning(true);
    try {
      const token = await AsyncStorage.getItem('jwtToken'); // Get the token directly
  
      if (!token) {
        throw new Error('Token not found. Please log in again.');
      }
  
      // Assign products to reseller
      for (const item of selectedItems) {
        const updateUrl = `https://juwelenorogem.be/wp-json/wc/v3/products/${item.id}`;
        const updatedMeta = [
          ...item.meta_data.filter(meta => meta.key !== 'location'),
          { key: 'location', value: customerData.id },
        ];
        const updatedProduct = { meta_data: updatedMeta };
  
        const response = await fetch(updateUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Add the token directly
          },
          body: JSON.stringify(updatedProduct),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `Failed to update product with ID: ${item.id}`);
        }
      }
  
      // Register the consignment in WordPress
      const consignmentData = {
        title: `Consignment ${invoiceDate.toLocaleDateString('en-GB').replace(/\//g, '-')}`,
        status: 'publish',
        acf: {
          reseller: customerData.id,
          products: selectedItems.map(item => item.id),
          date: invoiceDate.toISOString().split('T')[0].replace(/-/g, ''),
          custom_products: customProducts.map(product => ({
            name: product.name,
            sku: product.sku,
            price: parseFloat(product.price).toFixed(2),
            details: product.details,
          })),
          shipping_fee: parseFloat(shippingFee || 0).toFixed(2),
        },
      };
  
      const consignmentUrl = `https://juwelenorogem.be/wp-json/wp/v2/consignments`;
  
      const consignmentResponse = await fetch(consignmentUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Add the token directly
        },
        body: JSON.stringify(consignmentData),
      });
  
      if (!consignmentResponse.ok) {
        const errorData = await consignmentResponse.json();
        throw new Error(errorData.message || 'Failed to register consignment.');
      }
  
      setIsAssigned(true);
      Alert.alert('Success', 'Products assigned to the reseller and consignment registered successfully!');
    } catch (error) {
      console.error('Error assigning products:', error.message || error);
      Alert.alert('Error', error.message || 'Failed to assign products or register the consignment. Please try again.');
    } finally {
      setIsAssigning(false);
    }
  };
  
  
  
  
  

  const handleGoldWeightChange = (productId, weight) => {
    setGoldWeights(prevState => ({
      ...prevState,
      [productId]: weight
    }));
  };

  const handleTempPriceChange = (productId, price) => {
    setLocalPrices(prevState => ({
      ...prevState,
      [productId]: price
    }));
  };

  const handlePriceBlur = (productId) => {
    const price = localPrices[productId];
    const parsedPrice = parseFloat(price) || 0;
    const multipliedPrice = (parsedPrice * multiplier).toFixed(2).replace(/\.00$/, '');
    setOriginalPrices(prevState => ({
      ...prevState,
      [productId]: parsedPrice.toFixed(2).replace(/\.00$/, '')
    }));
    setItemsWithNewPrices(prevState => ({
      ...prevState,
      [productId]: multipliedPrice
    }));
    setTempPrices(prevState => ({
      ...prevState,
      [productId]: multipliedPrice
    }));
  };

  const handlePriceChange = (productId, price) => {
    const parsedPrice = parseFloat(price) || 0;
    const multipliedPrice = (parsedPrice * multiplier).toFixed(2).replace(/\.00$/, '');
    setOriginalPrices(prevState => ({
      ...prevState,
      [productId]: parsedPrice.toFixed(2).replace(/\.00$/, '')
    }));
    setItemsWithNewPrices(prevState => ({
      ...prevState,
      [productId]: multipliedPrice
    }));
    setTempPrices(prevState => ({
      ...prevState,
      [productId]: multipliedPrice
    }));
  };
  
  
  
  let updatePricesTimeout;

  const handleMultiplierChange = (value) => {
    let newMultiplier = parseFloat(value);
    if (isNaN(newMultiplier) || value.trim() === '') {
      newMultiplier = 1;
    }
    setMultiplier(newMultiplier);
    setItemsWithNewPrices(prevPrices => {
      const updatedPrices = {};
      for (const productId in prevPrices) {
        const originalPrice = parseFloat(originalPrices[productId]) || 0;
        updatedPrices[productId] = (originalPrice * newMultiplier).toFixed(2).replace(/\.00$/, '');
      }
      return updatedPrices;
    });
  };
  
  
  
  

  const getImageBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const printInvoiceForUs = async () => {
    try {
      const invoiceContent = await generateInvoiceContent(false);
      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(invoiceContent);
        win.print();
        win.close();
      } else {
        const { uri } = await printAsync({
          html: invoiceContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing invoice for us:", error);
    }
  };




  const extractDetails = (item) => {
    const details = {
      name: item.name,
      sku: item.sku,
      price: item.price_html, // This includes currency and formatting
      resellerPrice: item.meta_data.find(meta => meta.key === 'numberusd')?.value || item.price, // Use the reseller price if available
      shortDescription: item.short_description.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags
      attributes: item.attributes.map(attr => ({
        name: attr.name,
        value: attr.options.join(", ")
      })),
      images: item.images.map(img => img.src),
      shipping: {
        class: item.shipping_class,
        status: item.stock_status
      },
      additionalInfo: item.meta_data.reduce((info, meta) => {
        info[meta.key] = meta.value;
        return info;
      }, {})
    };

    // Extract laboratory name and certificate number separately
    details.laboratory = item.attributes.find(attr => attr.name === 'Certificate')?.options[0] || '';
    details.certificateNumber = item.attributes.find(attr => attr.name === 'Certificate number')?.options[0] || '';

    return details;
  };

  const formatStoneDetails = (details, attributeNames) => {
    const values = attributeNames.map(name => {
      const attribute = details.attributes.find(attr => attr.name.includes(name));
      return attribute ? attribute.value : null;
    });

    const stoneDetails = values.filter(Boolean).join(', ');

    return stoneDetails ? `${stoneDetails} ` : '';
  };

  const formatCentralStoneDetails = (details, attributeNames) => {
    const values = attributeNames.map(name => {
      const attribute = details.attributes.find(attr => attr.name.includes(name));
      return attribute ? attribute.value : null;
    });

    const stoneDetails = values.filter(Boolean).join(', ');
    const certificateDetails = details.laboratory && details.certificateNumber ? `, Certificate ${details.laboratory} ${details.certificateNumber}` : '';

    return `${stoneDetails}${certificateDetails}`;
  };

  const createPrintHTML = (cartItems, productImagesBase64, qrCodesBase64Map) => {
    let htmlContent = "<div style='text-align: center;'>";
  
    cartItems.map((item, index) => {
      const details = extractDetails(item);
      const mainImageHTML = productImagesBase64[item.id]
        ? `<img src="${productImagesBase64[item.id]}" style="width: 100px; height: auto; margin-right: 20px; margin-left: 20px;" />`
        : "";
      const qrCodeHTML = qrCodesBase64Map[item.id]
        ? `<img src="${qrCodesBase64Map[item.id]}" alt="QR Code" style="width: 80px; height: 80px;" />`
        : "";
  
      const centralStoneDetails = formatCentralStoneDetails(details, [
        "Weight Central Stone",
        "Color Central Stone",
        "Clarity Central Stone",
        "Gemstone Central Stone",
      ]);
  
      const sideStoneDetails = formatStoneDetails(details, [
        "Weight Side Stones",
        "Color Side Stones",
        "Clarity Side Stones",
        "Gemstone Side Stones",
      ]);
  
      const paveStoneDetails = formatStoneDetails(details, [
        "Pave diamonds weight",
        "Pave diamonds color",
        "Pave diamonds clarity",
      ]);
  
      const diamondDetails = formatStoneDetails(details, [
        "Diamond Carat",
        "Loose Diamond Color",
        "Diamond Clarity",
        "Diamond Shape",
        "Diamond Cut",
        "Diamond Polish",
        "Diamond Symmetry",
        "Diamond Fluorescence",
      ]);
  
      htmlContent += `
        <div key="${item.id}" style="display: flex; align-items: center; justify-content: space-between; border: 1px solid black; margin-bottom: 10px; margin-top: 10px; padding: 10px 20px;">
        
          <div style="flex: 1; margin-right: 10px;">
            ${mainImageHTML}
          </div>
          <div style="flex: 2; text-align: left;">
            <h3 style="font-size: 14px; margin-top: 0; margin-bottom: 5px;">${details.sku}</h3>
            <p style="font-size: 12px; margin: 0;"><strong>Price:</strong> ${formatEuro(itemsWithNewPrices[item.id])}</p>
            <p style="font-size: 12px; margin: 0;"><strong>SKU:</strong> ${details.sku}</p>
            ${centralStoneDetails ? `<p style="font-size: 12px; margin: 0;"><strong>Central Stone:</strong> ${centralStoneDetails}</p>` : ""}
            ${sideStoneDetails ? `<p style="font-size: 12px; margin: 0;"><strong>Side Stones:</strong> ${sideStoneDetails}</p>` : ""}
            ${paveStoneDetails ? `<p style="font-size: 12px; margin: 0;"><strong>Pavé Stones:</strong> ${paveStoneDetails}</p>` : ""}
            ${diamondDetails ? `<p style="font-size: 12px; margin: 0;"><strong>Loose Diamond Spec:</strong> ${diamondDetails}</p>` : ""}
          </div>
          <div style="flex: 1; text-align: right; margin-left: 10px;">
            ${qrCodeHTML}
          </div>
        </div>
      `;
    });
  
    customProducts.map((product, index) => {
      htmlContent += `
        <div key="${product.id}" style="display: flex; align-items: center; justify-content: space-between; border: 1px solid black; margin-bottom: 10px; margin-top: 10px; padding: 10px 20px;">
          <div style="flex: 1; margin-right: 10px;">
            <img src="https://via.placeholder.com/100" alt="Placeholder Image" style="width: 100px; height: auto; margin-right: 20px; margin-left: 20px;" />
          </div>
          <div style="flex: 2; text-align: left;">
            <h3 style="font-size: 14px; margin-top: 0; margin-bottom: 5px;">${product.name || 'Custom Product'}</h3>
            <p style="font-size: 12px; margin: 0;"><strong>Price:</strong> ${formatEuro(product.price)}</p>  
            <p style="font-size: 12px; margin: 0;"><strong>SKU:</strong> ${product.sku || '-'}</p>
            <p style="font-size: 12px; margin: 0;"><strong>Details:</strong> ${product.details}</p>
          </div>
          <div style="flex: 1; text-align: right; margin-left: 10px;">
           
          </div>
        </div>
      `;
    });
    
    
  
    htmlContent += "</div>";
    return htmlContent;
  };
  
  
  const removeCustomProduct = (index) => {
    setCustomProducts(customProducts.filter((_, i) => i !== index));
  };
  

  const preloadImages = async (urls) => {
    const promises = urls.map(url =>
      new Promise((resolve, reject) => {
        const img = new window.Image();
        img.src = url;
        img.onload = resolve; 
        img.onerror = reject;
      })
    );
    await Promise.all(promises);
  };

  const getBase64FromUrl = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          console.error('Error converting to Base64:', error);
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching URL:', error);
      throw error;
    }
  };

  const generateInvoiceContent = async () => {
    const logoBase64 = await Base64ImageComponent();
    const sortedSelectedItems = [...selectedItems].sort((a, b) => {
      if (a.sku < b.sku) return -1;
      if (a.sku > b.sku) return 1;
      return 0;
    });
    const productImageUrls = sortedSelectedItems.map(item => item.images?.[0]?.src).filter(Boolean);

    const qrCodeUrls = await Promise.all(
      sortedSelectedItems.map(async item => {
        const details = extractDetails(item);
        if (details.laboratory && details.certificateNumber) {
          let qrCodeURL = '';
          if (details.laboratory === 'GIA') {
            qrCodeURL = `https://gia.edu/report-check?reportno=${details.certificateNumber}`;
          } else if (details.laboratory === 'HRD') {
            qrCodeURL = `https://my.hrdantwerp.com/?id=34&record_number=${details.certificateNumber}`;
          } else if (details.laboratory === 'IGI') {
            qrCodeURL = `https://www.igi.org/verify-your-report/?r=${details.certificateNumber}`;
          }
          const qrCodeBase64 = await getBase64FromUrl(`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(qrCodeURL)}`);
          return { id: item.id, qrCodeBase64 };
        }
        return { id: item.id, qrCodeBase64: null };
      })
    );
  
    const qrCodesBase64Map = qrCodeUrls.reduce((acc, { id, qrCodeBase64 }) => {
      acc[id] = qrCodeBase64;
      return acc;
    }, {});
  
    const productImagesBase64 = await loadProductImagesBase64(selectedItems);
  
    const allImageUrls = [...productImageUrls, ...Object.values(qrCodesBase64Map).filter(Boolean)];
  
    await preloadImages(allImageUrls);
  
  // Calculate total before using it
  const total = selectedItems.reduce((acc, item) => acc + parseFloat(itemsWithNewPrices[item.id] || 0), 0) +
                customProducts.reduce((acc, product) => acc + parseFloat(product.price || 0), 0);
  const totalWithShipping = total + (includeShippingFee ? parseFloat(shippingFee || 0) : 0);
  const totalPieces = selectedItems.length + customProducts.length;

    const vatNumber = customerData.meta_data.find(meta => meta.key === 'vat_number')?.value || '';
    const phoneNumber = customerData.shipping.phone || '';
    
    const countryMapping = {
      AF: 'Afghanistan',
      AX: 'Aland Islands',
      AL: 'Albania',
      DZ: 'Algeria',
      AS: 'American Samoa',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AG: 'Antigua and Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaijan',
      BS: 'Bahamas',
      BH: 'Bahrain',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belgium',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia',
      BA: 'Bosnia and Herzegovina',
      BW: 'Botswana',
      BR: 'Brazil',
      IO: 'British Indian Ocean Territory',
      BN: 'Brunei',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      CV: 'Cabo Verde',
      KH: 'Cambodia',
      CM: 'Cameroon',
      CA: 'Canada',
      KY: 'Cayman Islands',
      CF: 'Central African Republic',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'China',
      CX: 'Christmas Island',
      CC: 'Cocos (Keeling) Islands',
      CO: 'Colombia',
      KM: 'Comoros',
      CG: 'Congo (Brazzaville)',
      CD: 'Congo (Kinshasa)',
      CK: 'Cook Islands',
      CR: 'Costa Rica',
      HR: 'Croatia',
      CU: 'Cuba',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DK: 'Denmark',
      DJ: 'Djibouti',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      EC: 'Ecuador',
      EG: 'Egypt',
      SV: 'El Salvador',
      GQ: 'Equatorial Guinea',
      ER: 'Eritrea',
      EE: 'Estonia',
      SZ: 'Eswatini',
      ET: 'Ethiopia',
      FK: 'Falkland Islands (Malvinas)',
      FO: 'Faroe Islands',
      FJ: 'Fiji',
      FI: 'Finland',
      FR: 'France',
      GF: 'French Guiana',
      PF: 'French Polynesia',
      TF: 'French Southern Territories',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Germany',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Greece',
      GL: 'Greenland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard Island and McDonald Islands',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hungary',
      IS: 'Iceland',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran',
      IQ: 'Iraq',
      IE: 'Ireland',
      IM: 'Isle of Man',
      IL: 'Israel',
      IT: 'Italy',
      JM: 'Jamaica',
      JP: 'Japan',
      JE: 'Jersey',
      JO: 'Jordan',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KP: 'Korea (North)',
      KR: 'Korea (South)',
      KW: 'Kuwait',
      KG: 'Kyrgyzstan',
      LA: 'Laos',
      LV: 'Latvia',
      LB: 'Lebanon',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libya',
      LI: 'Liechtenstein',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MO: 'Macao',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marshall Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexico',
      FM: 'Micronesia',
      MD: 'Moldova',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Morocco',
      MZ: 'Mozambique',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Netherlands',
      NC: 'New Caledonia',
      NZ: 'New Zealand',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Norfolk Island',
      MK: 'North Macedonia',
      MP: 'Northern Mariana Islands',
      NO: 'Norway',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestine, State of',
      PA: 'Panama',
      PG: 'Papua New Guinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippines',
      PN: 'Pitcairn',
      PL: 'Poland',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RU: 'Russia',
      RW: 'Rwanda',
      BL: 'Saint Barthélemy',
      SH: 'Saint Helena',
      KN: 'Saint Kitts and Nevis',
      LC: 'Saint Lucia',
      MF: 'Saint Martin (French)',
      PM: 'Saint Pierre and Miquelon',
      VC: 'Saint Vincent and the Grenadines',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Sao Tome and Principe',
      SA: 'Saudi Arabia',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SX: 'Sint Maarten (Dutch)',
      SK: 'Slovakia',
      SI: 'Slovenia',
      SB: 'Solomon Islands',
      SO: 'Somalia',
      ZA: 'South Africa',
      GS: 'South Georgia and the South Sandwich Islands',
      SS: 'South Sudan',
      ES: 'Spain',
      LK: 'Sri Lanka',
      SD: 'Sudan',
      SR: 'Suriname',
      SJ: 'Svalbard and Jan Mayen',
      SE: 'Sweden',
      CH: 'Switzerland',
      SY: 'Syria',
      TW: 'Taiwan',
      TJ: 'Tajikistan',
      TZ: 'Tanzania',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad and Tobago',
      TN: 'Tunisia',
      TR: 'Turkey',
      TM: 'Turkmenistan',
      TC: 'Turks and Caicos Islands',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraine',
      AE: 'United Arab Emirates',
      GB: 'United Kingdom',
      US: 'United States',
      UM: 'United States Minor Outlying Islands',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      VG: 'Virgin Islands (British)',
      VI: 'Virgin Islands (U.S.)',
      WF: 'Wallis and Futuna',
      EH: 'Western Sahara',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
    };
    
    
    const fullCountryName = countryMapping[customerData.billing.country] || customerData.billing.country;

    const consignmentContent = `
    <div style="text-align: center;">
      <img src="${logoBase64}" alt="Logo" style="width: 150px; height: auto; margin-bottom: 20px;" />
    </div>
    <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
      <div style="border: 1px solid black; padding: 10px; width: 45%">
        <p style="margin: 0;"><strong>${customerData.first_name} ${customerData.last_name}</strong></p>
        <p style="margin: 0;">${customerData.billing.company}</p>
        <p style="margin: 0;">${customerData.billing.address_1}, ${customerData.billing.city} ${customerData.billing.postcode}</p>
        <p style="margin: 0;">${fullCountryName}</p>
        <p style="margin: 0;">${vatNumber}</p>
        <p style="margin: 0;">${phoneNumber}</p>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px; margin-bottom: 20px; width: 100%;">
      <div>
        <p style="margin: 0;"><strong>Consignment No.</strong> ${invoiceNo}</p>
      </div>
      <div>
        <p style="margin: 0;"><strong>Antwerp</strong>, ${invoiceDate.toLocaleDateString()}</p>
      </div>
    </div>
    <table style="border-collapse: collapse; width: 100%; margin-left: auto;">
      <thead>
        <tr>
          <th style="border: 1px solid black; padding: 5px; font-size: 12px;">#</th>
          <th style="border: 1px solid black; padding: 5px; font-size: 12px;">Product Image</th>
          <th style="border: 1px solid black; padding: 5px; font-size: 12px;">N° Jewel</th>
          <th style="border: 1px solid black; padding: 5px; font-size: 12px;">Quantity</th>
          <th style="border: 1px solid black; padding: 5px; font-size: 12px;">Price/Unit</th>
        </tr>
      </thead>
      <tbody>
        ${sortedSelectedItems.map((item, index) => `
          <tr key="${item.id}">
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">${index + 1}</td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">
              <img src="${productImagesBase64[item.id] || 'https://via.placeholder.com/25'}" alt="Product Image" style="width: 25px; height: auto;" />
            </td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">${item.sku}</td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">1</td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">${formatEuro(itemsWithNewPrices[item.id])}</td>

          </tr>
        `).join('')}
        ${customProducts.map((product, index) => `
          <tr key="${product.id}">
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">${sortedSelectedItems.length + index + 1}</td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">
              <img src="https://via.placeholder.com/25" alt="Placeholder Image" style="width: 25px; height: auto;" />
            </td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">${product.sku}</td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">1</td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px; text-align: center;">${formatEuro(product.price)}</td>
          </tr>
        `).join('')}
      </tbody>
    </table>
    <div style="display: flex; justify-content: flex-end; margin-bottom: 20px; margin-top:20px">
      <table style="border-collapse: collapse;">
    <tbody>
      <tr>
        <td style="border: 1px solid black; padding: 10px; font-size: 12px;"><strong>Total Pieces</strong></td>
        <td style="border: 1px solid black; padding: 10px; font-size: 12px;">${totalPieces}</td>
      </tr>
      <tr>
        <td style="border: 1px solid black; padding: 10px; font-size: 12px;"><strong>Total</strong></td>
<td style="border: 1px solid black; padding: 10px; font-size: 12px;">${formatEuro(total)}</td>
      </tr>
      ${includeShippingFee ? `
      <tr>
        <td style="border: 1px solid black; padding: 10px; font-size: 12px;"><strong>Shipping Fee</strong></td>
<td style="border: 1px solid black; padding: 10px; font-size: 12px;">${formatEuro(parseFloat(shippingFee || 0))}</td>
      </tr>
      <tr>
        <td style="border: 1px solid black; padding: 10px; font-size: 12px;"><strong>Total with Shipping</strong></td>
<td style="border: 1px solid black; padding: 10px; font-size: 12px;">${formatEuro(totalWithShipping)}</td>
      </tr>` : ''}
    </tbody>
  </table>
    </div>
    <div style="margin-top: 40px; text-align: left; font-size: 14px;">
      <p>De ondergetekende: ${customerData.first_name} ${customerData.last_name} verklaart in COMMISSIE de volgende koopwaren van de firma The Shamrock BV ontvangen te hebben.</p>
      <p>Deze koopwaren mogen enkel MET ONZE TOELATING verkocht worden of moeten op verlangen van de eigenaar onmiddellijk teruggegeven worden.</p>
      <div style="display: flex; justify-content: flex-end; flex-direction: row;">
        <p style="margin-right: 20px;">Handtekening: ______________________________</p>
      </div>
    </div>
    <div class="page-break"></div>
  `;


  const detailedItemsContent = createPrintHTML(sortedSelectedItems, productImagesBase64, qrCodesBase64Map);

  const invoiceContent = `
    <html>
      <head>
        <style>
          @page { margin: 20mm; }
          @page :first {
            @bottom-center {
              content: element(footer);
            }
          }
          body {
            text-align: center;
            width: 90%;
            min-height: 100%;
            position: relative;
            padding-bottom: 100px;
            box-sizing: border-box;
            margin: auto;
            padding: 0;
          }
          .footer {
            position: running(footer);
            width: 100%;
            text-align: center;
            font-size: 12px;
          }
          .page-break { page-break-before: always; }
        </style>
      </head>
      <body>
        ${consignmentContent}
        ${consignmentContent}
        ${detailedItemsContent}
      </body>
    </html>
  `;

  return invoiceContent;
};
  
  
  
  
  
  

  const loadProductImagesBase64 = async (items) => {
    const imagesBase64 = {};
    for (const item of items) {
      if (item.images?.[0]?.src) {
        imagesBase64[item.id] = await getImageBase64(item.images[0].src);
      }
    }
    return imagesBase64;
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
    setModalVisible(true);
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        {loading ? (
          <Text style={styles.loadingText}>Loading...</Text>
        ) : (
          <ScrollView contentContainerStyle={styles.scrollView}>
            <View style={styles.row}>
              <Text style={[styles.columnTitle, { width: '10%' }]}>N° Jewel</Text>
              <Text style={[styles.columnTitle, { width: '15%' }]}>Product Image</Text>
              <Text style={[styles.columnTitle, { width: '15%' }]}>Price</Text>
              <Text style={[styles.columnTitle, { width: '20%' }]}>Weight Gold</Text>
              <Text style={[styles.columnTitle, { width: '15%' }]}>Gold/K.</Text>
              <Text style={[styles.columnTitle, { width: '15%' }]}>Price/Unit</Text>
            </View>

            {selectedItems.map((item, index) => (
  <View key={item.id} style={styles.row}>
    <Text style={styles.item}>{item.sku}</Text>
    {productImages[item.id] && (
      <TouchableOpacity onPress={() => openImageModal(productImages[item.id])}>
        <Image source={{ uri: productImages[item.id] }} style={styles.productImage} />
      </TouchableOpacity>
    )}
    <Text style={styles.item}>{itemsWithNewPrices[item.id]}</Text>
    <TextInput
      style={[styles.input, { width: '15%' }]}
      placeholder="Enter Weight (g)"
      placeholderTextColor="#888888"
      keyboardType="numeric"
      value={goldWeights[item.id] || ''}
      onChangeText={text => handleGoldWeightChange(item.id, text)}
    />
    <TextInput
      style={[styles.input, { width: '15%' }]}
      defaultValue="750"
      keyboardType="numeric"
      onChangeText={text => setGoldCarat(text)}
    />
<TextInput
  style={[styles.input, { width: '15%' }]}
  keyboardType="decimal-pad"
  value={localPrices[item.id] !== undefined ? localPrices[item.id] : itemsWithNewPrices[item.id]}
  onChangeText={text => handleTempPriceChange(item.id, text)}
  onBlur={() => handlePriceBlur(item.id)}
/>
  </View>
))}
<TouchableOpacity onPress={addCustomProduct} style={styles.button}>
  <Text style={styles.buttonText}>Add Custom Product</Text>
</TouchableOpacity>
{customProducts.map((product, index) => (
  <View key={product.id} style={styles.row}>
    <TextInput
      style={[styles.input, { width: '20%' }]}
      placeholder="Custom Product Name"
      value={product.name}
      onChangeText={(text) => handleCustomProductChange(index, 'name', text)}
    />
    <TextInput
      style={[styles.input, { width: '15%' }]}
      placeholder="SKU"
      value={product.sku}
      onChangeText={(text) => handleCustomProductChange(index, 'sku', text)}
    />
    <TextInput
      style={[styles.input, { width: '15%' }]}
      placeholder="Price"
      value={product.price}
      keyboardType="numeric"
      onChangeText={(text) => handleCustomProductChange(index, 'price', text)}
    />
    <TextInput
      style={[styles.input, { width: '35%' }]}  // Largest width for details
      placeholder="Additional Details"
      value={product.details}
      onChangeText={(text) => handleCustomProductChange(index, 'details', text)}
    />
    <TouchableOpacity onPress={() => removeCustomProduct(index)} style={styles.removeButton}>
      <Text style={styles.removeButtonText}>Remove</Text>
    </TouchableOpacity>
  </View>
))}
            <View style={styles.row}>
              <Text style={styles.columnTitle}>Customer:</Text>
              <Text style={styles.customerInfo}> {customerData.first_name} {customerData.last_name}</Text>
            </View>
            <View style={styles.row}>
  <Text style={styles.columnTitle}>Multiplier:</Text>
  <TextInput
  style={styles.input}
  keyboardType="decimal-pad"
  value={multiplier === 1 ? '' : multiplier.toString()}
  onChangeText={text => handleMultiplierChange(text)}
/>
</View>
<View style={styles.row}>
  <Text style={styles.columnTitle}>Consignment No.:</Text>
  <TextInput
    style={styles.input}
    placeholder="Enter Consignment No."
    value={invoiceNo}
    onChangeText={text => setInvoiceNo(text)}
  />
</View>

            <View style={styles.row}>
              <Text style={styles.columnTitle}>Consignment Date:</Text>
              {Platform.OS === 'web' ? (
                <DatePicker
                  selected={invoiceDate}
                  onChange={date => setInvoiceDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="custom-datepicker"
                  calendarClassName="custom-calendar"
                />
              ) : (
                <DateTimePicker
                  value={invoiceDate}
                  mode="date"
                  display="default"
                  onChange={(event, date) => setInvoiceDate(date)}
                  style={styles.input3}
                />
              )}
            </View>
            <View style={styles.row}>
  <Text style={styles.columnTitle}>Shipping Fee:</Text>
  <TextInput
    style={styles.input}
    placeholder="Enter Shipping Fee"
    keyboardType="decimal-pad"
    value={shippingFee}
    onChangeText={(value) => setShippingFee(value)}
  />
  <TouchableOpacity
    style={[styles.button, includeShippingFee && styles.buttonDisabled]}
    onPress={() => setIncludeShippingFee((prev) => !prev)}
  >
    <Text style={styles.buttonText}>
      {includeShippingFee ? 'Remove Shipping Fee' : 'Add Shipping Fee'}
    </Text>
  </TouchableOpacity>
</View>

            <TouchableOpacity style={[styles.button, loading && styles.buttonDisabled]} onPress={async () => await printInvoiceForUs()} disabled={loading}>
              <Text style={styles.buttonText}>Print Invoice for Us</Text>
            </TouchableOpacity>
            <TouchableOpacity
  style={[styles.button, (isAssigning || isAssigned) && styles.buttonDisabled]}
  onPress={handleBulkAssign}
  disabled={isAssigning || isAssigned}
>
  <Text style={styles.buttonText}>
    {isAssigned ? 'Assigned Successfully' : isAssigning ? 'Assigning...' : 'Assign Products to Reseller'}
  </Text>
</TouchableOpacity>

            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <View style={styles.modalView}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => setModalVisible(!modalVisible)}
                >
                  <Text style={styles.textStyle}>Close</Text>
                </TouchableOpacity>
                <Image source={{ uri: selectedImage }} style={styles.modalImage} />
              </View>
            </Modal>
          </ScrollView>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20
  },
  scrollView: {
    flexGrow: 1,
    paddingHorizontal: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    paddingVertical: 10,
    width: '100%',
    alignItems: 'center',
  },
  columnTitle: {
    color: 'black',
    fontSize: 15,
    fontWeight: 'bold',
    alignSelf: "center"
  },
  safeArea: {
    flex: 1,
  },
  item: {
    color: 'black',
    fontSize: 16,
    width: '10%',
  },
  productImage: {
    width: 50,
    height: 50,
  },
  customerInfo: {
    color: 'black',
    fontSize: 16,
    flex: 1,
  },
  buttonDisabled: {
    backgroundColor: 'grey',
  },
  input: {
    borderWidth: 1,
    borderColor: 'black',
    color: 'black',
    padding: 5,
    minWidth: 50,
    marginRight: 10,
  },
  input2: {
    borderWidth: 1,
    borderColor: 'black',
    color: 'black',
    padding: 10,
    width: 200,
    alignSelf: "center",
    backgroundColor: "white"
  },
  input3: {
    borderWidth: 1,
    borderColor: 'black',
    color: 'black',
    alignSelf: "center",
    backgroundColor: "white"
  },
  button: {
    backgroundColor: 'black',
    alignItems: 'center',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
  },
  '@global .custom-datepicker': {
    width: '50%',
    padding: '5px',
    color: 'black',
    backgroundColor: '#fff',
    border: '1px solid black',
    borderRadius: '5px',
    zIndex: 9999,
  },
  '@global .custom-calendar': {
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: '#fff',
    color: 'black',
  },
  loadingText: {
    color: 'black',
    fontSize: 18,
    textAlign: 'center',
    marginTop: 20,
  },
  modalView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  closeButton: {
    backgroundColor: '#2196F3',
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    position: 'absolute',
    top: 40,
    right: 20,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalImage: {
    width: '80%',
    height: '80%',
    resizeMode: 'contain',
  },
  removeButton: {
    backgroundColor: 'red',
    padding: 5,
    borderRadius: 5,
    marginLeft: 10,
  },
  removeButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default ResellerInvoice;
