import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, FlatList, TextInput, Button, Linking, TouchableOpacity } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { Picker } from '@react-native-picker/picker';
const proxyUrl = 'https://stripe-server-node-lbl0p.kinsta.app/proxy?url=';
const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
const userApiUrl = 'https://juwelenorogem.be/en/wp-json/wp/v2/users';
const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;

const Inventory = () => {
  const route = useRoute();
  const { categoryId, tagId } = route.params || {};
  const [editableProducts, setEditableProducts] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Start with false until cache is chosen
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState({});
  const [skuSearch, setSkuSearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [localInputs, setLocalInputs] = useState({});
  const [message, setMessage] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false); // New state for update status
  const [useCache, setUseCache] = useState(false); // State to determine cache usage
  const [isCacheChosen, setIsCacheChosen] = useState(false); // State to determine if cache option is chosen
  
  const debouncedSkuSearch = useDebounce(skuSearch, 500);
  const debouncedNameSearch = useDebounce(nameSearch, 500);
  const debouncedLocationFilter = useDebounce(locationFilter, 500);

  useEffect(() => {
    if (isCacheChosen) {
      fetchAllProducts(1, []);
    }
  }, [isCacheChosen, useCache]);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  useEffect(() => {
    applyFilters(data);
  }, [skuSearch, nameSearch, locationFilter, data]);

  useEffect(() => {
    applyFilters(data);
  }, [debouncedSkuSearch, debouncedNameSearch, debouncedLocationFilter, data]);

  const fetchUserById = async (userId) => {
    const url = `https://juwelenorogem.be/en/wp-json/wc/v3/customers/${userId}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;
    
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const user = await response.json();
      return `${user.first_name} ${user.last_name}`;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return 'N/A';
    }
  };
  const handleCacheSelection = (cacheSelection) => {
    setTimeout(() => {
      setUseCache(cacheSelection);
      setIsCacheChosen(true);
    }, 100); // Add a slight delay to make sure UI state updates correctly
  };
  const applyFilters = (products) => {
    if (!products || products.length === 0) return;
  
    let filtered = products;
  
    if (skuSearch) {
      filtered = filtered.filter(product => product.sku?.toLowerCase().includes(skuSearch.toLowerCase()));
    }
  
    if (nameSearch) {
      filtered = filtered.filter(product => product.name?.toLowerCase().includes(nameSearch.toLowerCase()));
    }
  
    if (locationFilter) {
      filtered = filtered.filter(product => product.locationName?.toLowerCase().includes(locationFilter.toLowerCase()));
    }
  
    if (!skuSearch && !nameSearch && !locationFilter) {
      filtered = products; // Reset to all products if no filters are applied
    }
  

    setFilteredData(filtered);
  };
  
  
  

  const searchUsers = async (query) => {
    const url = `https://juwelenorogem.be/en/wp-json/wc/v3/customers?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&search=${query}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const users = await response.json();
      setSearchResults(users);
    } catch (error) {
      console.error('Error searching users:', error);
    }
  };

  const fetchAllProducts = async (page, allProducts = [], retryCount = 0) => {
    setIsLoading(true); // Start loading
  
    const timestamp = new Date().getTime();
    let queryParams = `?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&orderby=price&order=asc&per_page=100&page=${page}`;
  
    if (!useCache) {
      queryParams += `&_=${timestamp}`; // Disable cache by appending a unique timestamp
    }
    if (categoryId) {
      queryParams += `&category=${categoryId}`;
    }
    if (tagId) {
      queryParams += `&tag=${tagId}`;
    }
  
    const url = `${proxyUrl}${encodeURIComponent(apiUrl + queryParams)}`;
  
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
  
      const data = await response.json();
      const allFetchedProducts = allProducts.concat(data);
  
      // If more pages exist, fetch recursively
      if (data.length === 100) {
        await fetchAllProducts(page + 1, allFetchedProducts);
      } else {
        setData(allFetchedProducts); // Set raw product data
        setFilteredData(allFetchedProducts); // Display products immediately
        setIsLoading(false); // Stop loading after products are displayed
  
        // Resolve metadata in the background
        resolveProductMetadata(allFetchedProducts);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      if (retryCount < 3) {
        setTimeout(() => fetchAllProducts(page, allProducts, retryCount + 1), 2000);
      } else {
        setIsLoading(false);
      }
    }
  };
  

const resolveProductMetadata = async (products) => {
  // Collect all unique location IDs
  const locationIds = products
    .map((product) => product.meta_data.find((meta) => meta.key === 'location')?.value)
    .filter(Boolean); // Filter out null/undefined values

  // Batch fetch user data
  const userMap = await fetchUsersInBatch(locationIds);

  const enrichedProducts = products.map((product) => {
    const locationMeta = product.meta_data.find((meta) => meta.key === 'location');
    const locationId = locationMeta ? locationMeta.value : null;
    const locationName = locationId ? userMap[locationId] || 'N/A' : 'N/A';
    
  
    return {
      ...product,
      locationName, // Ensure this is set
      numberusd: product.attributes.find((attr) => attr.id === 76)?.options[0] || '',
    };
  });
  

  // Populate editableProducts and filteredData
  setEditableProducts(
    enrichedProducts.reduce((acc, product) => {
      acc[product.id] = {
        price: product.regular_price,
        stock_quantity: product.stock_quantity,
        backorders_allowed: product.backorders,
        locationName: product.locationName,
        numberusd: product.numberusd,
      };
      return acc;
    }, {})
  );

  setFilteredData(enrichedProducts);
  setData(enrichedProducts); // Ensure the main data is updated
  applyFilters(enrichedProducts); // Trigger filtering with updated data
  setIsLoading(false);
};


const fetchUsersInBatch = async (userIds) => {
  const uniqueIds = Array.from(new Set(userIds)); // Remove duplicate IDs
  const userPromises = uniqueIds.map(async (id) => {
    const url = `https://juwelenorogem.be/en/wp-json/wc/v3/customers/${id}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Failed to fetch user with ID ${id}`);
      const user = await response.json();
      return { id, name: `${user.first_name} ${user.last_name}` };
    } catch (error) {
      console.error(`Error fetching user ${id}:`, error);
      return { id, name: 'N/A' };
    }
  });

  const userList = await Promise.all(userPromises);
  return userList.reduce((acc, user) => {
    acc[user.id] = user.name;
    return acc;
  }, {});
};

  
  

  const fetchProductById = async (id) => {
    const timestamp = new Date().getTime();
    const url = `${proxyUrl}${encodeURIComponent(`${apiUrl}/${id}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&_=${timestamp}`)}`;
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP status ${response.status}`);

      const product = await response.json();
      const locationMeta = product.meta_data.find(meta => meta.key === 'location');
      const numberusdAttr = product.attributes.find(attr => attr.id === 76);
      const locationId = locationMeta ? locationMeta.value : null;
      let locationName = 'N/A';
      let numberusd = numberusdAttr ? numberusdAttr.options[0] : ''; 

      if (locationId) {
        locationName = await fetchUserById(locationId);
      }

      const updatedProduct = { ...product, locationName, numberusd, backorders_allowed: product.backorders };
      setData(prevData => prevData.map(item => item.id === id ? updatedProduct : item));
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  const clearFilters = () => {
    setSkuSearch('');
    setNameSearch('');
    setLocationFilter('');
    applyFilters(data);
  };

  const handleInputChange = (id, field, value) => {
    setEditableProducts(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleLocalInputChange = (id, field, value) => {
    if (field === 'price' || field === 'numberusd') {
      setLocalInputs(prev => ({
        ...prev,
        [id]: {
          ...prev[id],
          [field]: value === '' ? '' : value, // Keep it empty if cleared
        },
      }));
    } else {
      setEditableProducts(prev => ({
        ...prev,
        [id]: {
          ...prev[id],
          [field]: value,
        },
      }));
    }
  };

  const commitChanges = (id, field) => {
    if (localInputs[id] && localInputs[id][field] !== undefined) {
        handleInputChange(id, field, localInputs[id][field]);
    } else if (editableProducts[id] && editableProducts[id][field] !== undefined) {
        // Commit changes from editableProducts if not found in localInputs
        handleInputChange(id, field, editableProducts[id][field]);
    } else {
        console.error(`Cannot commit changes: ${field} is undefined for product ID ${id}`);
    }
};

const handleUpdate = async (id) => {
  setIsUpdating(true);  // Show update in progress screen

  try {
    const timestamp = new Date().getTime();
    const getProductUrl = `${apiUrl}/${id}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&_=${timestamp}`;

    const productResponse = await fetch(getProductUrl);
    if (!productResponse.ok) throw new Error(`Failed to fetch product details: ${productResponse.status}`);

    const currentProduct = await productResponse.json();

    let metaData = [...currentProduct.meta_data];
    const locationMetaIndex = metaData.findIndex(meta => meta.key === 'location');

    if (editableProducts[id]?.locationName === '') {
      if (locationMetaIndex >= 0) {
        metaData[locationMetaIndex].value = null;
      }
    } else {
      if (locationMetaIndex >= 0) {
        metaData[locationMetaIndex].value = selectedUserId[id] || metaData[locationMetaIndex].value;
      } else if (selectedUserId[id]) {
        metaData.push({ key: 'location', value: selectedUserId[id] });
      }
    }


    let updatedAttributes = [...currentProduct.attributes];
    const numberusdAttrIndex = updatedAttributes.findIndex(attr => attr.id === 76);

    if (numberusdAttrIndex !== -1) {
      // If the attribute exists, update it
      updatedAttributes[numberusdAttrIndex].options = [editableProducts[id]?.numberusd || ''];
    } else {
      // If the attribute does not exist, create it
      updatedAttributes.push({
        id: 76, // Attribute ID for numberusd
        name: 'numberusd', // Ensure this matches the actual attribute name
        options: [editableProducts[id]?.numberusd || ''],
        visible: true,
        variation: false,
        position: updatedAttributes.length,
      });
    }


    const updatedData = {
      ...currentProduct,
      regular_price: editableProducts[id]?.price || currentProduct.regular_price,
      price: editableProducts[id]?.price || currentProduct.price,
      stock_quantity: editableProducts[id]?.stock_quantity ? parseInt(editableProducts[id].stock_quantity, 10) : currentProduct.stock_quantity,
      backorders: editableProducts[id]?.backorders_allowed || currentProduct.backorders,
      meta_data: metaData,
      attributes: updatedAttributes,
    };


    const updateProductUrl = `${apiUrl}/${id}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&_=${timestamp}`;

    const updateResponse = await fetch(updateProductUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
      body: JSON.stringify(updatedData),
    });

    if (!updateResponse.ok) throw new Error(`Update failed with status: ${updateResponse.status}`);


    const freshProductUrl = `${apiUrl}/${id}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&_=${new Date().getTime()}`;
    const freshProductResponse = await fetch(freshProductUrl);

    if (!freshProductResponse.ok) throw new Error(`Failed to fetch updated product details: ${freshProductResponse.status}`);

    const freshProduct = await freshProductResponse.json();

    setData(prevData => prevData.map(item => item.id === id ? freshProduct : item));
    setMessage('Product updated successfully!');
    setTimeout(() => setMessage(null), 3000);
  } catch (error) {
    console.error('Error updating product:', error);
    setMessage(`Error updating product: ${error.message}`);
    setTimeout(() => setMessage(null), 3000);
  } 
  finally {
    setIsUpdating(false);  // Hide update in progress screen
}
};
















  
  
  

  const handleLocationPress = (id) => {
    setSelectedProductId(id);
    setSearchQuery('');
    setSearchResults([]);
  };

  const handleLocationClear = (id) => {
  
    // Update the editableProducts state to reflect the cleared location
    setEditableProducts(prev => {
      const newEditableProducts = {
        ...prev,
        [id]: {
          ...prev[id],
          locationName: '',  // Clear the location in the state
        },
      };
      return newEditableProducts;
    });
  
    // Clear the selected user ID for the product
    setSelectedUserId(prev => {
      const newSelectedUserId = { ...prev, [id]: null };
      return newSelectedUserId;
    });
  
    // Clear the search results and close the search input
    setSearchResults([]);
    setSelectedProductId(null);
  };
  
  
  


  const renderItem = ({ item }) => (
    <View style={styles.row}>
      <Text style={styles.cell}>{item.name}</Text>
      <Text style={styles.cell}>{item.sku}</Text>
      <TextInput
        style={styles.cell}
        value={localInputs[item.id]?.price?.toString() || editableProducts[item.id]?.price?.toString() || item.regular_price?.toString()}
        onChangeText={(text) => handleLocalInputChange(item.id, 'price', text)}
        onBlur={() => commitChanges(item.id, 'price')}
      />
      <TextInput
        style={styles.cell}
        value={localInputs[item.id]?.numberusd?.toString() || editableProducts[item.id]?.numberusd?.toString() || item.numberusd}
        placeholder={item.numberusd === '' ? '' : undefined}
        onChangeText={text => handleLocalInputChange(item.id, 'numberusd', text)}
        onBlur={() => commitChanges(item.id, 'numberusd')}
      />
      <TextInput
        style={styles.cell}
        value={editableProducts[item.id]?.stock_quantity?.toString() || item.stock_quantity?.toString()}
        onChangeText={(text) => handleInputChange(item.id, 'stock_quantity', text)}
      />
      <Picker
        selectedValue={editableProducts[item.id]?.backorders_allowed || item.backorders_allowed}
        style={styles.cell}
        onValueChange={(value) => handleInputChange(item.id, 'backorders_allowed', value)}
      >
        <Picker.Item label="Yes" value="yes" />
        <Picker.Item label="No" value="no" />
      </Picker>
      <View style={styles.cell}>
  {selectedProductId === item.id ? (
    <TextInput
      style={styles.cell}
      placeholder="Search Users"
      value={searchQuery}
      onChangeText={(text) => {
        setSearchQuery(text);
        searchUsers(text);
      }}
    />
  ) : (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text onPress={() => handleLocationPress(item.id)}>
        {editableProducts[item.id]?.locationName || 'N/A'}
      </Text>
      {editableProducts[item.id]?.locationName && editableProducts[item.id]?.locationName !== 'N/A' && (
        <Text style={styles.clearButton} onPress={() => handleLocationClear(item.id)}>X</Text>
      )}
    </View>
  )}
  {selectedProductId === item.id && searchResults.map(user => (
    <Text
      key={user.id}
      onPress={() => {
        handleInputChange(item.id, 'locationName', `${user.first_name} ${user.last_name}`);
        setSelectedUserId((prev) => ({ ...prev, [item.id]: user.id }));
        setSelectedProductId(null);
      }}
    >
      {user.first_name} {user.last_name}
    </Text>
  ))}
</View>




      <Text style={[styles.cell, styles.linkText]} onPress={() => Linking.openURL(item.permalink)}>
        View on Web
      </Text>
      <Button title="Update" onPress={() => handleUpdate(item.id)} />
    </View>
  );

  if (isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }
  

  if (isUpdating) {
    return (
        <View style={styles.updateContainer}>
            <Text style={styles.updateText}>Update in progress, please wait...</Text>
        </View>
    );
}

if (!isCacheChosen) {
  return (
    <View style={styles.cacheOptionContainer}>
      <TouchableOpacity
        style={styles.cacheButton}
        onPress={() => handleCacheSelection(true)}
      >
        <Text style={styles.cacheButtonText}>Fetch Using Cache</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.cacheButton}
        onPress={() => handleCacheSelection(false)}
      >
        <Text style={styles.cacheButtonText}>Fetch Without Cache</Text>
      </TouchableOpacity>
    </View>
  );
}



  return (
    <View style={styles.container}>
      {message && <Text style={styles.message}>{message}</Text>}  

      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search by SKU"
          value={skuSearch}
          onChangeText={text => {
            setSkuSearch(text);
            applyFilters(data);
          }}
        />
        <TextInput
          style={styles.searchInput}
          placeholder="Search by Product Name"
          value={nameSearch}
          onChangeText={text => {
            setNameSearch(text);
            applyFilters(data);
          }}
        />
        <TextInput
          style={styles.searchInput}
          placeholder="Filter by Location"
          value={locationFilter}
          onChangeText={text => {
            setLocationFilter(text);
            applyFilters(data);
          }}
        />
        <Button title="Clear Filters" onPress={clearFilters} />
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.header]}>Name</Text>
        <Text style={[styles.cell, styles.header]}>SKU</Text>
        <Text style={[styles.cell, styles.header]}>Price</Text>
        <Text style={[styles.cell, styles.header]}>Numberusd</Text>
        <Text style={[styles.cell, styles.header]}>Stock Quantity</Text>
        <Text style={[styles.cell, styles.header]}>Backorder Allowed</Text>
        <Text style={[styles.cell, styles.header]}>Location</Text>
        <Text style={[styles.cell, styles.header]}>Link</Text>
      </View>
      <FlatList
        data={filteredData}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  message: {
    padding: 10,
    backgroundColor: 'lightgreen',
    textAlign: 'center',
    marginBottom: 10,
    borderRadius: 5,
  },
  searchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  updateContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
},
updateText: {
    color: 'white',
    fontSize: 18,
},
  searchInput: {
    flex: 1,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginHorizontal: 5,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingVertical: 10,
  },
  cell: {
    flex: 1,
    fontSize: 16,
    paddingHorizontal: 10,
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
  header: {
    fontWeight: 'bold',
  },
  clearButton: {
    marginLeft: 10,
    color: 'red',
    fontWeight: 'bold',
},
cacheOptionContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  marginVertical: 20,
},
cacheButton: {
  backgroundColor: '#007bff',
  paddingVertical: 15,
  paddingHorizontal: 30,
  marginVertical: 10,
  borderRadius: 5,
},
cacheButtonText: {
  color: '#fff',
  fontSize: 18,
  textAlign: 'center',
  fontWeight: 'bold',
},
});

export default Inventory;
