// HomeStack.js
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './HomeScreen';
import Inventory from './Inventory';
import Selection  from './Selection';
import NewCustomer from './NewCustomer';
import NewReseller from './NewReseller';
import NewOrder from './NewOrder';
import ExistingCustomer from './ExistingCustomer';
import OrderInventory from './OrderInventory';
import ConfirmOrder from './ConfirmOrder';
import CertificateScreen from './CertificateScreen';
import PosHome from './PosHome';
import OrderDetails from './OrderDetails';


const Stack = createNativeStackNavigator();

const PosStack = () => {
  return (
    <Stack.Navigator
    screenOptions={{
      headerStyle: {
        backgroundColor: '#000', // Black header background
      },
      headerTintColor: '#FFF', // White header text
    }}
    >
      <Stack.Screen name="Home" component={PosHome} options={{ headerShown: false }} />
      <Stack.Screen name="Selection" component={Selection} />
      <Stack.Screen name="Inventory" component={Inventory} />
      <Stack.Screen name="New Customer" component={NewCustomer} />
      <Stack.Screen name="New Reseller" component={NewReseller} />
      <Stack.Screen name="New Order" component={NewOrder} />
      <Stack.Screen name="Existing Customer" component={ExistingCustomer} />
      <Stack.Screen name="Order Inventory" component={OrderInventory} />
      <Stack.Screen name="Confirm Order" component={ConfirmOrder} />
      <Stack.Screen name="Print Certificate" component={CertificateScreen} />
      <Stack.Screen name="OrderDetails" component={OrderDetails} />

    </Stack.Navigator>
  );
};

export default PosStack;
