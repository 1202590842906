import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, FlatList, TextInput, Button, Linking, TouchableOpacity } from 'react-native';
import { useRoute } from '@react-navigation/native';

const proxyUrl = 'https://stripe-server-node-lbl0p.kinsta.app/proxy?url=';
const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;

const InventoryTemp = () => {
  const route = useRoute();
  const { categoryId, tagId } = route.params || {};
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isCacheChosen, setIsCacheChosen] = useState(false);
  const [useCache, setUseCache] = useState(false);
  const [hiddenItems, setHiddenItems] = useState([]);

  useEffect(() => {
    if (isCacheChosen) {
      fetchAllProducts(1, []);
    }
  }, [isCacheChosen, useCache]);

  const fetchAllProducts = async (page, allProducts = [], retryCount = 0) => {
    setIsLoading(true);
    const timestamp = new Date().getTime();
    let queryParams = `?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&orderby=price&order=asc&per_page=100&page=${page}`;

    if (!useCache) {
      queryParams += `&_=${timestamp}`;
    }
    if (categoryId) {
      queryParams += `&category=${categoryId}`;
    }
    if (tagId) {
      queryParams += `&tag=${tagId}`;
    }

    const url = `${proxyUrl}${encodeURIComponent(apiUrl + queryParams)}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }

      const data = await response.json();
      const allFetchedProducts = allProducts.concat(data);

      if (data.length === 100) {
        await fetchAllProducts(page + 1, allFetchedProducts);
      } else {
        const enrichedProducts = allFetchedProducts.map(product => {
          const videoMetaData = product.meta_data.find(obj => obj.key === "_ywcfav_video");
          const videoPath = videoMetaData ? "YES" : "NO";
          const iframeMetaData = product.meta_data.find(obj => obj.key.startsWith("_oembed"));
          const iframeVideoPath = iframeMetaData ? "YES" : "NO";

          return { ...product, videoPath, iframeVideoPath };
        });

        setData(enrichedProducts);
        setFilteredData(enrichedProducts);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      if (retryCount < 3) {
        setTimeout(() => fetchAllProducts(page, allProducts, retryCount + 1), 2000);
      } else {
        setIsLoading(false);
      }
    }
  };

  const applyFilters = (filterType = null) => {
    if (!data || data.length === 0) return;
  
    let filtered = data;
  
    // Exclude items with SKU starting with "DIA"
    filtered = filtered.filter(product => !product.sku?.toUpperCase().startsWith("DIA"));
  
    // Apply additional filters based on filterType
    if (filterType === 'video1') {
      filtered = filtered.filter(product => product.videoPath === "YES");
    } else if (filterType === 'video2') {
      filtered = filtered.filter(product => product.iframeVideoPath === "YES");
    } else if (filterType === 'noVideo') {
      filtered = filtered.filter(product => product.videoPath === "YES" || product.iframeVideoPath === "YES");
    }
  
    if (searchQuery) {
      filtered = filtered.filter(product => product.name?.toLowerCase().includes(searchQuery.toLowerCase()));
    }
  
    setFilteredData(filtered);
  };

  const clearFilters = () => {
    setSearchQuery('');
    applyFilters();
  };

  const handleCacheSelection = (cacheSelection) => {
    setTimeout(() => {
      setUseCache(cacheSelection);
      setIsCacheChosen(true);
    }, 100);
  };

  const renderItem = ({ item }) => {
    if (hiddenItems.includes(item.id)) {
      return null; // Skip rendering if the item is hidden
    }
  
    let backgroundColor = "#fff";
  
    if (item.videoPath === "YES" && item.iframeVideoPath === "NO") {
      backgroundColor = "red";
    } else if (item.videoPath === "NO" && item.iframeVideoPath === "YES") {
      backgroundColor = "green";
    } else if (item.videoPath === "YES" && item.iframeVideoPath === "YES") {
      backgroundColor = "yellow";
    }
  
    return (
      <View style={[styles.row, { backgroundColor }]}>
  <Text style={styles.cell}>{item.name}</Text>
  <Text style={styles.cell}>{item.sku}</Text>
  <Text style={styles.cell}>{item.videoPath}</Text>
  <Text style={styles.cell}>{item.iframeVideoPath}</Text>
  <Text style={[styles.cell, styles.linkText]} onPress={() => Linking.openURL(item.permalink)}>
    View on Web
  </Text>
  <TouchableOpacity
    style={styles.removeButton}
    onPress={() => setHiddenItems((prev) => [...prev, item.id])}
  >
    <Text style={styles.removeButtonText}>Remove</Text>
  </TouchableOpacity>
</View>
    );
  };
  

  if (isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  if (!isCacheChosen) {
    return (
      <View style={styles.cacheOptionContainer}>
        <TouchableOpacity style={styles.cacheButton} onPress={() => handleCacheSelection(true)}>
          <Text style={styles.cacheButtonText}>Fetch Using Cache</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.cacheButton} onPress={() => handleCacheSelection(false)}>
          <Text style={styles.cacheButtonText}>Fetch Without Cache</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search by Product Name"
          value={searchQuery}
          onChangeText={text => {
            setSearchQuery(text);
            applyFilters();
          }}
        />
        <Button title="Clear Filters" onPress={() => applyFilters()} />
      </View>
      <View style={styles.buttonContainer}>
        <Button title="Show Video URL 1" onPress={() => applyFilters('video1')} />
        <Button title="Show Video URL 2" onPress={() => applyFilters('video2')} />
        <Button title="Hide No Videos" onPress={() => applyFilters('noVideo')} />
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.header]}>Name</Text>
        <Text style={[styles.cell, styles.header]}>SKU</Text>
        <Text style={[styles.cell, styles.header]}>Video URL 1</Text>
        <Text style={[styles.cell, styles.header]}>Video URL 2</Text>
        <Text style={[styles.cell, styles.header]}>Link</Text>
      </View>
      <FlatList
        data={filteredData}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20, backgroundColor: '#fff' },
  searchContainer: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 },
  searchInput: { flex: 1, borderColor: '#ccc', borderWidth: 1, borderRadius: 5, padding: 10, marginHorizontal: 5 },
  row: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#ccc', paddingVertical: 10 },
  cell: { flex: 1, fontSize: 16, paddingHorizontal: 10 },
  header: { fontWeight: 'bold', textAlign: 'left' },
  linkText: { color: 'blue', textDecorationLine: 'underline' },
  cacheOptionContainer: { flex: 1, justifyContent: 'center', alignItems: 'center', marginVertical: 20 },
  cacheButton: { backgroundColor: '#007bff', paddingVertical: 15, paddingHorizontal: 30, marginVertical: 10, borderRadius: 5 },
  cacheButtonText: { color: '#fff', fontSize: 18, textAlign: 'center', fontWeight: 'bold' },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  removeButton: {
    backgroundColor: 'black',
    padding: 5,
    borderRadius: 5,
  },
  removeButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default InventoryTemp;
