import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Alert, Switch } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';

const NewCustomer = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [useDifferentShipping, setUseDifferentShipping] = useState(false);
  const route = useRoute();

  const [shipFirstName, setShipFirstName] = useState('');
  const [shipLastName, setShipLastName] = useState('');
  const [shipAddress, setShipAddress] = useState('');
  const [shipCity, setShipCity] = useState('');
  const [shipState, setShipState] = useState('');
  const [shipPostcode, setShipPostcode] = useState('');
  const [shipCountry, setShipCountry] = useState('');

  const [countries, setCountries] = useState([]);
  const [billingStates, setBillingStates] = useState([]);
  const [shippingStates, setShippingStates] = useState([]);
  const [isBillingStateFieldVisible, setIsBillingStateFieldVisible] = useState(false);
  const [isShippingStateFieldVisible, setIsShippingStateFieldVisible] = useState(false);

  const navigation = useNavigation();

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await fetch(
        `https://juwelenorogem.be/en/wp-json/wc/v3/data/countries?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`
      );
      const data = await response.json();
      setCountries(Object.entries(data));
    } catch (error) {
      console.error('Error fetching countries:', error);
      Alert.alert('Error', 'Failed to fetch country data.');
    }
  };

  const handleBillingCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    setState('');
    const countryData = countries.find(([code]) => code === selectedCountry);
    if (countryData && countryData[1]?.states?.length) {
      setBillingStates(countryData[1].states);
      setIsBillingStateFieldVisible(true);
    } else {
      setBillingStates([]);
      setIsBillingStateFieldVisible(false);
    }
  };

  const handleShippingCountryChange = (selectedCountry) => {
    setShipCountry(selectedCountry);
    setShipState('');
    const countryData = countries.find(([code]) => code === selectedCountry);
    if (countryData && countryData[1]?.states?.length) {
      setShippingStates(countryData[1].states);
      setIsShippingStateFieldVisible(true);
    } else {
      setShippingStates([]);
      setIsShippingStateFieldVisible(false);
    }
  };

  const handleAddCustomer = async () => {
    if (!firstName || !lastName || !email || !address || !city || !country || (!postcode && isBillingStateFieldVisible)) {
      Alert.alert('Error', 'Please fill out all required fields.');
      return;
    }
  
    if (useDifferentShipping && (!shipFirstName || !shipLastName || !shipAddress || !shipCity || !shipCountry)) {
      Alert.alert('Error', 'Please fill out all required shipping address fields.');
      return;
    }
  
    const customerData = {
      username: `${firstName.toLowerCase()}.${lastName.toLowerCase()}`, // Generate username
      first_name: firstName,
      last_name: lastName,
      email,
      password: "password",
      billing: {
        first_name: firstName,
        last_name: lastName,
        address_1: address,
        city,
        state,
        postcode,
        country,
        email,
        phone,
      },
      shipping: useDifferentShipping
        ? {
            first_name: shipFirstName,
            last_name: shipLastName,
            address_1: shipAddress,
            city: shipCity,
            state: shipState,
            postcode: shipPostcode,
            country: shipCountry,
          }
        : {
            first_name: firstName,
            last_name: lastName,
            address_1: address,
            city,
            state,
            postcode,
            country,
          },
    };
  
    try {
      const response = await fetch(
        `https://juwelenorogem.be/en/wp-json/wc/v3/customers?consumer_key=${process.env.EXPO_PUBLIC_CONSUMER_KEY}&consumer_secret=${process.env.EXPO_PUBLIC_CONSUMER_SECRET}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(customerData),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create customer.');
      }
  
      const createdCustomer = await response.json(); // Get the newly created customer data
  
      if (route.params?.sourceScreen === 'NewOrder') {
        // Redirect to Order Inventory if the source screen is NewOrder
        navigation.navigate('Order Inventory', { customer: createdCustomer });
      } else {
        setRegistrationSuccess(true);
      }
    } catch (error) {
      console.error('Error adding customer:', error);
      Alert.alert('Error', `Failed to add customer: ${error.message}`);
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
      {registrationSuccess ? (
  <View style={styles.successContainer}>
    <Text style={styles.successText}>Customer added successfully!</Text>
    <TouchableOpacity style={styles.button} onPress={() => setRegistrationSuccess(false)}>
      <Text style={styles.text}>Register Another Customer</Text>
    </TouchableOpacity>
    <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Home')}>
      <Text style={styles.text}>Go to Homepage</Text>
    </TouchableOpacity>
  </View>
): (
          <>
            <TextInput
              style={styles.input}
              placeholder="First Name"
              value={firstName}
              onChangeText={setFirstName}
            />
            <TextInput
              style={styles.input}
              placeholder="Last Name"
              value={lastName}
              onChangeText={setLastName}
            />
            <TextInput
              style={styles.input}
              placeholder="Email"
              value={email}
              onChangeText={setEmail}
              keyboardType="email-address"
            />
            <TextInput
              style={styles.input}
              placeholder="Address"
              value={address}
              onChangeText={setAddress}
            />
            <TextInput
              style={styles.input}
              placeholder="City"
              value={city}
              onChangeText={setCity}
            />
            <Picker
              selectedValue={country}
              onValueChange={handleBillingCountryChange}
              style={styles.picker}
            >
              <Picker.Item label="Select a country" value="" />
              {countries.map(([code, details]) => (
                <Picker.Item key={code} label={details.name} value={code} />
              ))}
            </Picker>
            {isBillingStateFieldVisible && (
              <Picker
                selectedValue={state}
                onValueChange={(value) => setState(value)}
                style={styles.picker}
              >
                <Picker.Item label="Select a state" value="" />
                {billingStates.map((state) => (
                  <Picker.Item key={state.code} label={state.name} value={state.code} />
                ))}
              </Picker>
            )}
            <TextInput
              style={styles.input}
              placeholder="Postcode"
              value={postcode}
              onChangeText={setPostcode}
            />
            <TextInput
              style={styles.input}
              placeholder="Phone"
              value={phone}
              onChangeText={setPhone}
              keyboardType="phone-pad"
            />
            <View style={styles.switchContainer}>
              <Text style={styles.switchLabel}>Use Different Shipping Address</Text>
              <Switch
                value={useDifferentShipping}
                onValueChange={setUseDifferentShipping}
              />
            </View>
            {useDifferentShipping && (
              <>
                <TextInput
                  style={styles.input}
                  placeholder="Shipping First Name"
                  value={shipFirstName}
                  onChangeText={setShipFirstName}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping Last Name"
                  value={shipLastName}
                  onChangeText={setShipLastName}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping Address"
                  value={shipAddress}
                  onChangeText={setShipAddress}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping City"
                  value={shipCity}
                  onChangeText={setShipCity}
                />
                <Picker
                  selectedValue={shipCountry}
                  onValueChange={handleShippingCountryChange}
                  style={styles.picker}
                >
                  <Picker.Item label="Select a country" value="" />
                  {countries.map(([code, details]) => (
                    <Picker.Item key={code} label={details.name} value={code} />
                  ))}
                </Picker>
                {isShippingStateFieldVisible && (
                  <Picker
                    selectedValue={shipState}
                    onValueChange={(value) => setShipState(value)}
                    style={styles.picker}
                  >
                    <Picker.Item label="Select a state" value="" />
                    {shippingStates.map((state) => (
                      <Picker.Item key={state.code} label={state.name} value={state.code} />
                    ))}
                  </Picker>
                )}
                <TextInput
                  style={styles.input}
                  placeholder="Shipping Postcode"
                  value={shipPostcode}
                  onChangeText={setShipPostcode}
                />
              </>
            )}
            <TouchableOpacity style={styles.button} onPress={handleAddCustomer}>
              <Text style={styles.text}>Add Customer</Text>
            </TouchableOpacity>
          </>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  successContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  successText: {
    color: 'green',
    fontSize: 18,
    marginBottom: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 10,
    marginVertical: 10,
    width: '80%',
  },
  button: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 20,
    alignItems: 'center',
    marginTop: 20,
  },
  text: {
    color: 'black',
    fontSize: 16,
  },
  picker: {
    width: '80%',
    marginVertical: 10,
    padding: 10,
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  switchLabel: {
    marginRight: 10,
    fontSize: 16,
  },
});

export default NewCustomer;
